import {
  CREATE_STRATEGY_PACK_ERROR,
  CREATE_STRATEGY_PACK_REQUEST,
  CREATE_STRATEGY_PACK_SUCCESS,
  CREATE_WATCHLISTS_ERROR,
  CREATE_WATCHLISTS_REQUEST,
  CREATE_WATCHLISTS_SUCCESS,
  DELETE_STRATEGY_PACK_ERROR,
  DELETE_STRATEGY_PACK_REQUEST,
  DELETE_STRATEGY_PACK_SUCCESS,
  DELETE_WATCHLISTS_ERROR,
  DELETE_WATCHLISTS_REQUEST,
  DELETE_WATCHLISTS_SUCCESS,
  EDIT_STRATEGY_PACK_ERROR,
  EDIT_STRATEGY_PACK_REQUEST,
  EDIT_STRATEGY_PACK_SUCCESS,
  GET_AVAILABLE_MARKETS_ERROR_NEW,
  GET_AVAILABLE_MARKETS_REQUEST_NEW,
  GET_AVAILABLE_MARKETS_SUCCESS_NEW,
  GET_INSIGHT_CHART_DATA_ERROR,
  GET_INSIGHT_CHART_DATA_REQUEST,
  GET_INSIGHT_CHART_DATA_SUCCESS,
  GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
  GET_INSIGHT_INITIAL_INFO_REQUEST_NEW,
  GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
  GET_STRATEGY_SIGNALS_ERRORS,
  GET_STRATEGY_SIGNALS_REQUEST,
  GET_STRATEGY_SIGNALS_SUCCESS,
  GET_SYSTEM_STRATEGY_PACK_LIST_ERROR,
  GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST,
  GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS,
  GET_USER_STRATEGY_PACK_LIST_ERROR,
  GET_USER_STRATEGY_PACK_LIST_REQUEST,
  GET_USER_STRATEGY_PACK_LIST_SUCCESS,
  GET_WATCHLISTS_ERROR,
  GET_WATCHLISTS_REQUEST,
  GET_WATCHLISTS_SUCCESS,
  MODIFY_WATCHLISTS_ERROR,
  MODIFY_WATCHLISTS_REQUEST,
  MODIFY_WATCHLISTS_SUCCESS,
  SET_CURRENT_STRATEGY,
} from "./consts";
import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";
import alert from "../../components/alerts/Alert";

const BASE_URL =
  "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api";

export const getInsightInitialDataNew =
  (email, user, market_type, only_tree_categories = "False", retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_INSIGHT_INITIAL_INFO_REQUEST_NEW });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        market_type,
        request_type: "get_initial_info",
        only_tree_categories,
      })
      .then((res) => {
        dispatch({
          type: GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
          payload: res?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getInsightInitialDataNew(
              email,
              user,
              market_type,
              (only_tree_categories = "False"),
              retryCount + 1,
            ),
          GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
          user,
          email,
          "insight/get_initial_info",
        );
      });
  };

export const getInsightAvailableMarkets =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_AVAILABLE_MARKETS_REQUEST_NEW });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        request_type: "get_available_markets",
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: GET_AVAILABLE_MARKETS_SUCCESS_NEW });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getInsightAvailableMarkets(user, email, retryCount + 1),
          GET_AVAILABLE_MARKETS_ERROR_NEW,
          user,
          email,
          "insight/get_available_markets",
        );
      });
  };

export const createStrategyPack =
  (
    user,
    email,
    market_type,
    strategy_name,
    strategy_label,
    strategy_tag,
    feature_list,
    extraOptions = null,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: CREATE_STRATEGY_PACK_REQUEST });

    let data = {
      platform_mode: "insight_platform",
      email,
      user,
      market_type,
      request_type: "create_strategy_packs",
      strategy_name,
      feature_list,
      strategy_label,
      strategy_tag,
    };

    if (extraOptions) data = { ...data, ...extraOptions };

    await axios
      .post(BASE_URL, data)
      .then((res) => {
        console.log(res);
        dispatch({
          type: CREATE_STRATEGY_PACK_SUCCESS,
          payload: res?.data?.message,
        });
        alert("", res?.data?.message, "success", {
          cancel: "Close",
        });

        dispatch(getUserStrategies(user, email, market_type));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            createStrategyPack(
              user,
              email,
              market_type,
              strategy_name,
              extraOptions,
              strategy_tag,
              feature_list,
              extraOptions,
              retryCount + 1,
            ),
          CREATE_STRATEGY_PACK_ERROR,
          user,
          email,
          "create_strategy_packs",
        );
      });
  };

export const getUserStrategies =
  (user, email, market_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_USER_STRATEGY_PACK_LIST_REQUEST });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        market_type,
        request_type: "get_user_strategy_packs",
      })
      .then((res) => {
        dispatch({
          type: GET_USER_STRATEGY_PACK_LIST_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getUserStrategies(user, email, market_type, retryCount + 1),
          GET_USER_STRATEGY_PACK_LIST_ERROR,
          user,
          email,
          "get_user_strategy_packs",
        );
      });
  };

export const getSystemStrategies =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        special_strategy: "system",
        request_type: "get_user_strategy_packs",
      })
      .then((res) => {
        dispatch({
          type: GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getSystemStrategies(user, email, retryCount + 1),
          GET_SYSTEM_STRATEGY_PACK_LIST_ERROR,
          user,
          email,
          "get_user_strategy_packs",
        );
      });
  };

export const editStrategy =
  (
    user,
    email,
    created_date,
    fields_to_edit,
    attribute_values_to_edit,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: EDIT_STRATEGY_PACK_REQUEST });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        request_type: "edit_user_strategy_pack",
        created_date,
        fields_to_edit,
        attribute_values_to_edit,
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: EDIT_STRATEGY_PACK_SUCCESS });
        alert("", res?.data?.message, "success", {
          cancel: "Close",
        });

        dispatch(getUserStrategies(user, email, "crypto"));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            editStrategy(
              user,
              email,
              created_date,
              fields_to_edit,
              attribute_values_to_edit,
              retryCount + 1,
            ),
          EDIT_STRATEGY_PACK_ERROR,
          user,
          email,
          "edit_user_strategy_pack",
        );
      });
  };

export const deleteStrategy =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DELETE_STRATEGY_PACK_REQUEST });

    await axios
      .post(BASE_URL, {
        platform_mode: "insight_platform",
        email,
        user,
        request_type: "delete_strategy_packs",
        created_date,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: DELETE_STRATEGY_PACK_SUCCESS,
          payload: res?.data?.message,
        });
        alert("", res?.data?.message, "success", {
          cancle: "Close",
        });

        dispatch(getUserStrategies(user, email, "crypto"));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deleteStrategy(user, email, created_date, retryCount + 1),
          DELETE_STRATEGY_PACK_ERROR,
          user,
          email,
          "delete_strategy_packs",
        );
      });
  };

export const getStrategySignal =
  (user, email, created_date, isSystem = false, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_STRATEGY_SIGNALS_REQUEST });

    let data = {
      platform_mode: "insight_platform",
      email,
      user,
      request_type: "query_signals_strategy_packs",
      created_date,
    };

    if (isSystem) data["special_strategy"] = "system";

    await axios
      .post(BASE_URL, data)
      .then((res) => {
        console.log(res);
        dispatch({ type: GET_STRATEGY_SIGNALS_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getStrategySignal(
              user,
              email,
              created_date,
              isSystem,
              retryCount + 1,
            ),
          GET_STRATEGY_SIGNALS_ERRORS,
          user,
          email,
          "query_signals_strategy_packs",
          40,
        );
      });
  };

export const getChartData =
  (user, email, symbol, indicator, chart_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_INSIGHT_CHART_DATA_REQUEST });

    await axios
      .post(
        "https://s9o986dbe2.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "insight_platform",
          email,
          user,
          request_type: "get_chart_data",
          chart_id,
          symbol,
          indicator,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: GET_INSIGHT_CHART_DATA_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getChartData(
              user,
              email,
              symbol,
              indicator,
              chart_id,
              retryCount + 1,
            ),
          GET_INSIGHT_CHART_DATA_ERROR,
          user,
          email,
          "insight/get_chart_data",
        );
      });
  };

export const setCurrentStrategy = (strategyInfo) => (dispatch) => {
  dispatch({ type: SET_CURRENT_STRATEGY, payload: strategyInfo });
};

export const getWatchLists =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_WATCHLISTS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "list_watchlists",
        platform_mode: "insight_platform",
        user,
        email,
      })
      .then((res) => {
        dispatch({
          type: GET_WATCHLISTS_SUCCESS,
          payload: res?.data?.watchlists,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getWatchLists(user, email, retryCount + 1),
          GET_WATCHLISTS_ERROR,
          user,
          email,
          "list_watchlists",
        );
      });
  };

export const createWatchList =
  (user, email, list_of_coins, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: CREATE_WATCHLISTS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "create_watchlist",
        platform_mode: "insight_platform",
        user,
        email,
        list_of_coins,
      })
      .then((res) => {
        dispatch({
          type: CREATE_WATCHLISTS_SUCCESS,
          payload: res?.data?.message,
        });
        alert("", res?.data?.message, "success", {
          cancel: "Close",
        });
        dispatch(getWatchLists(user, email));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => createWatchList(user, email, list_of_coins, retryCount + 1),
          CREATE_WATCHLISTS_ERROR,
          user,
          email,
          "create_watchlist",
        );
      });
  };

export const deleteWatchlist =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DELETE_WATCHLISTS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "delete_watchlist",
        platform_mode: "insight_platform",
        user,
        email,
        created_date,
      })
      .then((res) => {
        dispatch({
          type: DELETE_WATCHLISTS_SUCCESS,
          payload: res?.data?.message,
        });
        alert("", res?.data?.message, "success", {
          cancel: "Close",
        });
        dispatch(getWatchLists(user, email));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deleteWatchlist(user, email, created_date, retryCount + 1),
          DELETE_WATCHLISTS_ERROR,
          user,
          email,
          "delete_watchlist",
        );
      });
  };

export const modifyWatchlist =
  (user, email, created_date, list_of_coins, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: MODIFY_WATCHLISTS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "modify_watchlist",
        platform_mode: "insight_platform",
        user,
        email,
        created_date,
        updated_data: {
          list_of_coins,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: MODIFY_WATCHLISTS_SUCCESS,
          payload: res?.data?.message,
        });
        alert("", res?.data?.message, "success", {
          cancel: "Close",
        });
        dispatch(getWatchLists(user, email));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            modifyWatchlist(
              user,
              email,
              created_date,
              list_of_coins,
              retryCount + 1,
            ),
          MODIFY_WATCHLISTS_ERROR,
          user,
          email,
          "modify_watchlist",
        );
      });
  };
