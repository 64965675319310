export const GET_INSIGHT_INITIAL_INFO_REQUEST_NEW =
  "GET_INSIGHT_INITIAL_INFO_REQUEST_NEW";
export const GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW =
  "GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW";
export const GET_INSIGHT_INITIAL_INFO_ERROR_NEW =
  "GET_INSIGHT_INITIAL_INFO_ERROR_NEW";

export const GET_AVAILABLE_MARKETS_REQUEST_NEW =
  "GET_AVAILABLE_MARKETS_REQUEST_NEW";
export const GET_AVAILABLE_MARKETS_SUCCESS_NEW =
  "GET_AVAILABLE_MARKETS_SUCCESS_NEW";
export const GET_AVAILABLE_MARKETS_ERROR_NEW = "GET_AVAILABLE_MARKETS_ERROR";

export const CREATE_STRATEGY_PACK_REQUEST = "CREATE_STRATEGY_PACK_REQUEST";
export const CREATE_STRATEGY_PACK_SUCCESS = "CREATE_STRATEGY_PACK_SUCCESS";
export const CREATE_STRATEGY_PACK_ERROR = "CREATE_STRATEGY_PACK_ERROR";

export const GET_USER_STRATEGY_PACK_LIST_REQUEST =
  "GET_USER_STRATEGY_PACK_LIST_REQUEST";
export const GET_USER_STRATEGY_PACK_LIST_SUCCESS =
  "GET_USER_STRATEGY_PACK_LIST_SUCCESS";
export const GET_USER_STRATEGY_PACK_LIST_ERROR =
  "GET_USER_STRATEGY_PACK_LIST_ERROR";

export const GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST =
  "GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST";
export const GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS =
  "GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS";
export const GET_SYSTEM_STRATEGY_PACK_LIST_ERROR =
  "GET_SYSTEM_STRATEGY_PACK_LIST_ERROR";

export const EDIT_STRATEGY_PACK_REQUEST = "EDIT_STRATEGY_PACK_REQUEST";
export const EDIT_STRATEGY_PACK_SUCCESS = "EDIT_STRATEGY_PACK_SUCCESS";
export const EDIT_STRATEGY_PACK_ERROR = "EDIT_STRATEGY_PACK_ERROR";

export const DELETE_STRATEGY_PACK_REQUEST = "DELETE_STRATEGY_PACK_REQUEST";
export const DELETE_STRATEGY_PACK_SUCCESS = "DELETE_STRATEGY_PACK_SUCCESS";
export const DELETE_STRATEGY_PACK_ERROR = "DELETE_STRATEGY_PACK_ERROR";

export const GET_STRATEGY_SIGNALS_REQUEST = "GET_STRATEGY_SIGNALS_REQUEST";
export const GET_STRATEGY_SIGNALS_SUCCESS = "GET_STRATEGY_SIGNALS_SUCCESS";
export const GET_STRATEGY_SIGNALS_ERRORS = "GET_STRATEGY_SIGNALS_ERRORS";

export const GET_INSIGHT_CHART_DATA_REQUEST = "GET_INSIGHT_CHART_DATA_REQUEST";
export const GET_INSIGHT_CHART_DATA_SUCCESS = "GET_INSIGHT_CHART_DATA_SUCCESS";
export const GET_INSIGHT_CHART_DATA_ERROR = "GET_INSIGHT_CHART_DATA_ERROR";

export const SET_INSIGHT_MARKET_TYPE = "SET_INSIGHT_MARKET_TYPE";

export const SET_CURRENT_STRATEGY = "SET_CURRENT_STRATEGY";

export const GET_WATCHLISTS_REQUEST = "GET_WATCHLISTS_REQUEST";
export const GET_WATCHLISTS_SUCCESS = "GET_WATCHLISTS_SUCCESS";
export const GET_WATCHLISTS_ERROR = "GET_WATCHLISTS_ERROR";

export const CREATE_WATCHLISTS_REQUEST = "CREATE_WATCHLISTS_REQUEST";
export const CREATE_WATCHLISTS_SUCCESS = "CREATE_WATCHLISTS_SUCCESS";
export const CREATE_WATCHLISTS_ERROR = "CREATE_WATCHLISTS_ERROR";

export const MODIFY_WATCHLISTS_REQUEST = "MODIFY_WATCHLISTS_REQUEST";
export const MODIFY_WATCHLISTS_SUCCESS = "MODIFY_WATCHLISTS_SUCCESS";
export const MODIFY_WATCHLISTS_ERROR = "MODIFY_WATCHLISTS_ERROR";

export const DELETE_WATCHLISTS_REQUEST = "DELETE_WATCHLISTS_REQUEST";
export const DELETE_WATCHLISTS_SUCCESS = "DELETE_WATCHLISTS_SUCCESS";
export const DELETE_WATCHLISTS_ERROR = "DELETE_WATCHLISTS_ERROR";
