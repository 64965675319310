import {
  CREATE_STRATEGY_PACK_ERROR,
  CREATE_STRATEGY_PACK_REQUEST,
  CREATE_STRATEGY_PACK_SUCCESS,
  CREATE_WATCHLISTS_ERROR,
  CREATE_WATCHLISTS_REQUEST,
  CREATE_WATCHLISTS_SUCCESS,
  DELETE_STRATEGY_PACK_ERROR,
  DELETE_STRATEGY_PACK_REQUEST,
  DELETE_STRATEGY_PACK_SUCCESS,
  DELETE_WATCHLISTS_ERROR,
  DELETE_WATCHLISTS_REQUEST,
  DELETE_WATCHLISTS_SUCCESS,
  EDIT_STRATEGY_PACK_ERROR,
  EDIT_STRATEGY_PACK_REQUEST,
  EDIT_STRATEGY_PACK_SUCCESS,
  GET_AVAILABLE_MARKETS_ERROR_NEW,
  GET_AVAILABLE_MARKETS_REQUEST_NEW,
  GET_AVAILABLE_MARKETS_SUCCESS_NEW,
  GET_INSIGHT_CHART_DATA_ERROR,
  GET_INSIGHT_CHART_DATA_REQUEST,
  GET_INSIGHT_CHART_DATA_SUCCESS,
  GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
  GET_INSIGHT_INITIAL_INFO_REQUEST_NEW,
  GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
  GET_STRATEGY_SIGNALS_ERRORS,
  GET_STRATEGY_SIGNALS_REQUEST,
  GET_STRATEGY_SIGNALS_SUCCESS,
  GET_SYSTEM_STRATEGY_PACK_LIST_ERROR,
  GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST,
  GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS,
  GET_USER_STRATEGY_PACK_LIST_ERROR,
  GET_USER_STRATEGY_PACK_LIST_REQUEST,
  GET_USER_STRATEGY_PACK_LIST_SUCCESS,
  GET_WATCHLISTS_ERROR,
  GET_WATCHLISTS_REQUEST,
  GET_WATCHLISTS_SUCCESS,
  MODIFY_WATCHLISTS_ERROR,
  MODIFY_WATCHLISTS_REQUEST,
  MODIFY_WATCHLISTS_SUCCESS,
  SET_CURRENT_STRATEGY,
  SET_INSIGHT_MARKET_TYPE,
} from "./consts";
import { getAllfeatures } from "../../utility/helper";

const initialState = {
  getInitialDataLoading: false,
  coefficients: {},
  overallStatistics: {},
  categories: [],
  categoriesTooltip: {},
  categoriesFeatureTree: {},
  allFeatures: [],
  getInitialDataError: "",
  initialDataFetched: false,
  insightMarketType: "crypto",
  availableMarkets: {},
  availableMarketsStatus: "",
  availableMarketsError: "",
  createStrategyStatus: "",
  createStrategyMessage: "",
  createStrategyError: "",
  userStrategies: [],
  isUserStrategiesFetched: false,
  userStrategiesStatus: "",
  userStrategiesError: "",
  systemStrategies: [],
  systemStrategiesStatus: "",
  systemStrategiesError: "",
  editStrategyStatus: "",
  editStrategyMessage: "",
  editStrategyError: "",
  deleteStrategyStatus: "",
  deleteStrategyMessage: "",
  deleteStrategyError: "",
  signalData: [],
  chartId: "",
  signalStatus: "",
  signalError: "",
  chartData: {},
  chartStatus: "",
  chartError: "",
  signalTableColumns: ["Signals"],
  currentStrategy: {},
  createWatchlistStatus: "",
  createWatchlistError: "",
  createWatchlistMessage: "",
  watchlistStatus: "",
  watchlists: [],
  watchlistError: "",
  watchlistsFetched: false,
  deleteWatchListStatus: "",
  deleteWatchListMessage: "",
  deleteWatchListError: "",
  modifyWatchListStatus: "",
  modifyWatchListMessage: "",
  modifyWatchListError: "",
};

export const newInsightReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INSIGHT_INITIAL_INFO_REQUEST_NEW:
      return {
        ...state,
        getInitialDataLoading: true,
        getInitialDataError: "",
      };

    case GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW:
      return {
        ...state,
        getInitialDataLoading: false,
        overallStatistics: payload?.OVERALL_STATISTICS,
        categories: payload?.CATEGORIES,
        categoriesFeatureTree: payload?.CATEGORIES_FEATURE_TREE,
        categoriesTooltip: payload?.categories_tooltip_mapping,
        allFeatures: getAllfeatures(payload?.CATEGORIES_FEATURE_TREE),
        initialDataFetched: true,
        coefficients: payload?.coefficients,
      };

    case GET_INSIGHT_INITIAL_INFO_ERROR_NEW:
      return {
        ...state,
        getInitialDataLoading: false,
        getInitialDataError: payload,
      };

    case SET_INSIGHT_MARKET_TYPE:
      return {
        ...state,
        insightMarketType: payload,
      };

    case GET_AVAILABLE_MARKETS_REQUEST_NEW:
      return {
        ...state,
        availableMarketsStatus: "loading",
        availableMarketsError: "",
      };
    case GET_AVAILABLE_MARKETS_SUCCESS_NEW:
      return {
        ...state,
        availableMarketsStatus: "idle",
        availableMarkets: payload,
      };
    case GET_AVAILABLE_MARKETS_ERROR_NEW:
      return {
        ...state,
        availableMarketsError: payload,
        availableMarketsStatus: "error",
      };

    case CREATE_STRATEGY_PACK_REQUEST:
      return {
        ...state,
        createStrategyStatus: "loading",
        createStrategyMessage: "",
        createStrategyError: "",
      };
    case CREATE_STRATEGY_PACK_SUCCESS:
      return {
        ...state,
        createStrategyStatus: "idle",
        createStrategyMessage: payload,
      };
    case CREATE_STRATEGY_PACK_ERROR:
      return {
        ...state,
        createStrategyStatus: "error",
        createStrategyError: payload,
      };

    case GET_USER_STRATEGY_PACK_LIST_REQUEST:
      return {
        ...state,
        userStrategiesStatus: "loading",
        userStrategiesError: "",
      };
    case GET_USER_STRATEGY_PACK_LIST_SUCCESS:
      return {
        ...state,
        userStrategiesStatus: "idle",
        userStrategies: payload,
        isUserStrategiesFetched: true,
      };
    case GET_USER_STRATEGY_PACK_LIST_ERROR:
      return {
        ...state,
        userStrategiesStatus: "error",
        userStrategiesError: payload,
      };

    case GET_SYSTEM_STRATEGY_PACK_LIST_REQUEST:
      return {
        ...state,
        systemStrategiesStatus: "loading",
        systemStrategiesError: "",
      };
    case GET_SYSTEM_STRATEGY_PACK_LIST_SUCCESS:
      return {
        ...state,
        systemStrategiesStatus: "idle",
        systemStrategies: payload,
      };
    case GET_SYSTEM_STRATEGY_PACK_LIST_ERROR:
      return {
        ...state,
        systemStrategiesStatus: "error",
        systemStrategiesError: payload,
      };

    case EDIT_STRATEGY_PACK_REQUEST:
      return {
        ...state,
        editStrategyStatus: "loading",
        editStrategyError: "",
      };
    case EDIT_STRATEGY_PACK_SUCCESS:
      return {
        ...state,
        editStrategyStatus: "idle",
        editStrategyMessage: payload,
      };
    case EDIT_STRATEGY_PACK_ERROR:
      return {
        ...state,
        editStrategyStatus: "error",
        editStrategyError: payload,
      };

    case DELETE_STRATEGY_PACK_REQUEST:
      return {
        ...state,
        deleteStrategyStatus: "loading",
        editStrategyError: "",
      };
    case DELETE_STRATEGY_PACK_SUCCESS:
      return {
        ...state,
        deleteStrategyStatus: "idle",
        deleteStrategyMessage: payload,
      };
    case DELETE_STRATEGY_PACK_ERROR:
      return {
        ...state,
        deleteStrategyStatus: "error",
        deleteStrategyError: payload,
      };

    case GET_STRATEGY_SIGNALS_REQUEST:
      return {
        ...state,
        signalStatus: "loading",
        signalError: "",
        // chartId: ""
      };
    case GET_STRATEGY_SIGNALS_SUCCESS:
      return {
        ...state,
        signalStatus: "idle",
        signalData: payload?.signals,
        chartId: payload?.chart_id,
        signalTableColumns: Object.keys(payload?.signals[0]),
      };
    case GET_STRATEGY_SIGNALS_ERRORS:
      return {
        ...state,
        signalStatus: "error",
        signalError: payload,
      };

    case GET_INSIGHT_CHART_DATA_REQUEST:
      return {
        ...state,
        chartStatus: "loading",
        chartError: "",
      };
    case GET_INSIGHT_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartStatus: "idle",
        chartData: payload,
      };
    case GET_INSIGHT_CHART_DATA_ERROR:
      return {
        ...state,
        chartStatus: "error",
        chartError: payload,
      };

    case SET_CURRENT_STRATEGY:
      return {
        ...state,
        currentStrategy: payload,
      };

    case GET_WATCHLISTS_REQUEST:
      return {
        ...state,
        watchlistStatus: "loading",
        watchlistError: "",
      };
    case GET_WATCHLISTS_SUCCESS:
      return {
        ...state,
        watchlists: payload,
        watchlistStatus: "idle",
        watchlistsFetched: true,
      };
    case GET_WATCHLISTS_ERROR:
      return {
        ...state,
        watchlistStatus: "error",
        watchlistError: payload,
      };

    case CREATE_WATCHLISTS_REQUEST:
      return {
        ...state,
        createWatchlistStatus: "loading",
        createWatchlistError: "",
        createWatchlistMessage: "",
      };
    case CREATE_WATCHLISTS_SUCCESS:
      return {
        ...state,
        createWatchlistStatus: "idle",
        createWatchlistMessage: payload,
      };
    case CREATE_WATCHLISTS_ERROR:
      return {
        ...state,
        createWatchlistStatus: "error",
        createWatchlistError: payload,
      };

    case DELETE_WATCHLISTS_REQUEST:
      return {
        ...state,
        deleteWatchListStatus: "loading",
        deleteWatchListMessage: "",
        deleteWatchListError: "",
      };
    case DELETE_WATCHLISTS_SUCCESS:
      return {
        ...state,
        deleteWatchListStatus: "idle",
        deleteWatchListMessage: payload,
      };
    case DELETE_WATCHLISTS_ERROR:
      return {
        ...state,
        deleteWatchListStatus: "error",
        deleteWatchListError: payload,
      };

    case MODIFY_WATCHLISTS_REQUEST:
      return {
        ...state,
        modifyWatchListStatus: "loading",
        modifyWatchListError: "",
        modifyWatchListMessage: "",
      };
    case MODIFY_WATCHLISTS_SUCCESS:
      return {
        ...state,
        modifyWatchListStatus: "idle",
        modifyWatchListMessage: payload,
      };
    case MODIFY_WATCHLISTS_ERROR:
      return {
        ...state,
        modifyWatchListStatus: "error",
        modifyWatchListError: payload,
      };

    default:
      return state;
  }
};
