import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Tabs } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { IoNotifications, IoNotificationsOff } from "react-icons/io5";
import {
  createWatchList,
  deleteWatchlist,
  modifyWatchlist,
} from "../../../redux/new-insight/actions";

const TabContent = ({ symbols, values }) => {
  const {
    main: { userData },
    newInsightState: {
      getInitialDataLoading,
      watchlists,
      watchlistStatus,
      createWatchlistStatus,
      deleteWatchListStatus,
      modifyWatchListStatus,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleWatchlistActions = (isCoinInWatchlist, coin) => {
    if (watchlistStatus === "loading") return;

    if (watchlists.length === 0) {
      return dispatch(
        createWatchList(userData["custom:custom_username"], userData?.email, [
          coin,
        ]),
      );
    }

    const currentWatchlist = watchlists?.[0];
    const createdDate = currentWatchlist?.createdDate;
    const coins =
      typeof currentWatchlist?.list_of_coins === "string"
        ? JSON.parse(currentWatchlist?.list_of_coins?.replace(/'/g, '"'))
        : currentWatchlist?.list_of_coins;

    if (coins.length === 1 && isCoinInWatchlist) {
      return dispatch(
        deleteWatchlist(
          userData["custom:custom_username"],
          userData?.email,
          createdDate,
        ),
      );
    }

    const updatedCoinsList = isCoinInWatchlist
      ? coins.filter((c) => c !== coin)
      : [...coins, coin];
    dispatch(
      modifyWatchlist(
        userData["custom:custom_username"],
        userData?.email,
        createdDate,
        updatedCoinsList,
      ),
    );
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (_, __, index) => index + 1, // Display index + 1
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      render: (coin) => (
        <span>
          <img
            style={{ width: "28px", height: "28px", borderRadius: "100%" }}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${coin}.png`}
            alt={coin}
          />
          {coin}
        </span>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (text) => (
        <span style={{ color: `${text > 0 ? "#27ae60" : "#e74c3c"}` }}>
          {text}
        </span>
      ),
    },
    {
      title: "Notification",
      key: "notification",
      align: "center",
      render: (_, record) => {
        const isCoinInList = watchlists?.[0]?.list_of_coins?.includes(
          record.symbol,
        ); // Check if the coin is in the list

        return (
          <Button
            className={styles.buyNow}
            disabled={watchlistStatus === "loading"}
            loading={
              createWatchlistStatus === "loading" ||
              modifyWatchListStatus === "loading"
            }
            onClick={() => handleWatchlistActions(isCoinInList, record.symbol)}
          >
            {isCoinInList ? (
              <IoNotificationsOff /> // Show IoNotificationsOff if in the list
            ) : (
              <IoNotifications /> // Show IoNotifications if not in the list
            )}
          </Button>
        );
      },
    },
  ];

  const dataSource = symbols?.map((symbol, index) => ({
    key: index,
    symbol,
    value: values[index],
  }));

  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={getInitialDataLoading}
    />
  );
};
const Trends = () => {
  const {
    newInsightState: { overallStatistics },
  } = useSelector((state) => state);

  const tabs = [
    {
      label: "Tops",
      key: "tops",
      dataKey: "TOP_20",
      symbolKey: "TOP_20_SYMBOLS",
    },
    {
      label: "Top Gainers",
      key: "top_gainers",
      dataKey: "TOP_20_Gainers",
      symbolKey: "TOP_20_Gainers_SYMBOLS",
    },
    {
      label: "Top Losers",
      key: "top_losers",
      dataKey: "TOP_20_losers",
      symbolKey: "TOP_20_losers_SYMBOLS",
    },
    {
      label: "Top Relatively Losers/Gainers",
      key: "top_relatively_losers_gainers",
      dataKey: "TOP_20_Relatively_Gainers",
      symbolKey: "TOP_20_Relatively_Gainers_SYMBOLS",
    },
  ];

  return (
    <Tabs>
      {tabs?.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          <TabContent
            symbols={overallStatistics?.[tab.symbolKey]}
            values={overallStatistics?.[tab.dataKey]}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default Trends;
