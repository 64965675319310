import Trends from "./watchlist/Trends";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWatchLists } from "../../redux/new-insight/actions";

function InsightWatchlist() {
  const {
    main: { userData },
    newInsightState: { watchlists, watchlistStatus, watchlistsFetched },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userData?.email &&
      !watchlistsFetched &&
      watchlistStatus !== "loading" &&
      watchlistStatus !== "error"
    ) {
      dispatch(
        getWatchLists(userData["custom:custom_username"], userData?.email),
      );
    }
  }, [userData, watchlists, watchlistStatus]);

  return (
    <>
      <Trends />
    </>
  );
}

export default InsightWatchlist;
