import styles from "../../../styles/Components/new-insight/signals-section/InsightSignalOperations.module.css";
import { Button } from "antd";
import StrategyDropdown from "./StrategyDropdown";
import React, { useEffect, useState } from "react";
import CreateStrategyModalV2 from "../create-strategy/CreateStrategyModalV2";
import { useDispatch, useSelector } from "react-redux";
import useServiceStatus from "../../../hooks/useServiceStatus";
import {
  getStrategySignal,
  getSystemStrategies,
  getUserStrategies,
} from "../../../redux/new-insight/actions";
import { LuRefreshCcw } from "react-icons/lu";
import { useSearchParams } from "react-router-dom";

function InsightSignalOperations() {
  const {
    main: { userData },
    newInsightState: {
      userStrategies,
      userStrategiesStatus,
      systemStrategies,
      systemStrategiesStatus,
      isUserStrategiesFetched,
      signalStatus,
      currentStrategy,
    },
  } = useSelector((state) => state);

  const [showCreateStrategyModal, setShowCreateStrategyModal] = useState(false);
  const [searchParams] = useSearchParams();
  const strategy = searchParams.get("strategy") || currentStrategy?.createdDate;

  const dispatch = useDispatch();

  const handleRefresh = () => {
    if (signalStatus === "loading") return;
    if (strategy) {
      console.log(strategy, currentStrategy);
      dispatch(
        getStrategySignal(
          userData["custom:custom_username"],
          userData?.email,
          strategy,
          currentStrategy?.user_info === "system",
        ),
      );
    }
  };

  useEffect(() => {
    if (
      userData?.email &&
      userStrategiesStatus !== "loading" &&
      userStrategiesStatus !== "error" &&
      !isUserStrategiesFetched
    ) {
      dispatch(
        getUserStrategies(
          userData["custom:custom_username"],
          userData?.email,
          "crypto",
        ),
      );
    }
  }, [
    dispatch,
    isUserStrategiesFetched,
    userData,
    userData?.email,
    userStrategiesStatus,
  ]);

  useEffect(() => {
    if (
      userData?.email &&
      systemStrategiesStatus !== "loading" &&
      systemStrategiesStatus !== "error" &&
      !systemStrategies.length
    ) {
      dispatch(
        getSystemStrategies(
          userData["custom:custom_username"],
          userData?.email,
        ),
      );
    }
  }, [
    dispatch,
    systemStrategies.length,
    systemStrategiesStatus,
    userData,
    userData?.email,
  ]);

  return (
    <div className={styles.operations}>
      <Button
        className={styles.featuresBtn}
        onClick={() => setShowCreateStrategyModal(true)}
      >
        Create
      </Button>

      <StrategyDropdown
        data={userStrategies}
        title="Manage"
        loading={userStrategiesStatus === "loading"}
        type="user"
      />

      <StrategyDropdown
        data={systemStrategies}
        title="Templates"
        loading={systemStrategiesStatus === "loading"}
        type="system"
      />

      <Button
        loading={signalStatus === "loading"}
        onClick={handleRefresh}
        className={styles.refreshBtn}
      >
        {signalStatus !== "loading" && <LuRefreshCcw />} Refresh
      </Button>

      {showCreateStrategyModal && (
        <CreateStrategyModalV2
          show={showCreateStrategyModal}
          closeHandler={() => setShowCreateStrategyModal(false)}
          type={"createStrategy"}
        />
      )}
    </div>
  );
}

export default InsightSignalOperations;
